@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url('asset/font/Roboto-Bold.woff2') format('woff2'),
       url('asset/font/Roboto-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Roboto';
  src: local('Roboto'), url('asset/font/Roboto-Regular.woff2') format('woff2'),
       url('asset/font/Roboto-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

html, body {
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* letter-spacing: -.0412em; */
  height: 100%;
  min-height: 100%;
  width: 100%;
}

#root {
  height: 100%;
}

div {
  box-sizing: border-box; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
